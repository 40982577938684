const DISPLAY_BIG = 1180;
const DISPLAY_MEDIUM = 767;

const SHOWN_MOVIES_BIG = 12;
const SHOWN_MOVIES_MEDIUM = 8;
const SHOWN_MOVIES_SMALL = 5;

const EXTRA_MOVIES_BIG = 3;
const EXTRA_MOVIES_MEDIUM = 2;

const PATTERN_EMAIL = "[a-z0-9]+@[a-z]+\\.{1,1}[a-z]{2,}"
const PATTERN_NAME = "^[A-Za-zА-Яа-яЁё/s-]+$"

export {
    DISPLAY_BIG,
    DISPLAY_MEDIUM,
    SHOWN_MOVIES_BIG,
    SHOWN_MOVIES_MEDIUM,
    SHOWN_MOVIES_SMALL,
    EXTRA_MOVIES_BIG,
    EXTRA_MOVIES_MEDIUM,
    PATTERN_EMAIL,
    PATTERN_NAME
}
